<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Agregar post</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Images-->
        <div class="mb-5">
          <div class="grid grid-cols-2 -mx-2">
            <div class="p-2">
              <!-- Image 1 -->
              <template v-if="document.image1">
                <!-- Image Container -->
                <div class="img-container flex items-center justify-center">
                  <img :src="document.image1" alt="img" class="responsive">
                </div>
                <!-- Image upload Buttons -->
                <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="document.image1=null">Eliminar</span>
                </div>
              </template>
              <!--End-->
            </div>
          </div>
          <div class="grid grid-cols-2 -mx-2">
            <!-- Upload image 1 -->
            <div class="upload-img mt-5 p-2" v-if="!document.image1">
              <input id="image1" type="file" class="hidden" ref="uploadImgInput1" @change="updateImage"
                     accept="image/*">
              <vs-button style="width: 100%" @click="$refs.uploadImgInput1.click()">1 (1080x1350)</vs-button>
            </div>
            <!--End-->
          </div>
        </div>
        <!--End-->

        <!-- Title -->
        <div class="mb-5">
          <vs-input @blur="document.title= trimString(document.title)" label="Título*"
                    v-model="document.title"
                    class="w-full" name="title"
                    v-validate="'required|min:3|max:150'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('title')">{{ errors.first('title') }}</span>
        </div>
        <!--End-->

        <!-- Description -->
        <div>
          <quill-editor rows="20" @blur="document.description= trimString(document.description)"
                        v-model="document.description"
                        label="Descripción*"
                        class="w-full"
                        name="description"
                        v-validate="'required|min:5|max:5000'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('description')">{{ errors.first('description') }}</span>
        </div>
        <!--End-->

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import { auth, db, FieldValue, storage } from '@/firebase/firebaseConfig'

import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  name: 'NewPost',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    flatPickr,
    quillEditor
  },
  mixins: [trimString],
  data () {
    return {
      document: {},
      newImage1: null,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      deleteImageFlag: false,
      // Date
      configDateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.initValues()
        this.$validator.reset()
      }
    },
    document: {
      handler () {
        if (this.document.image1 === null) {
          this.newImage1 = null
        }
      },
      deep: true
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          // Images
          if (this.newImage1 != null) {
            this.document.image1 = await this.submitImages(this.newImage1)
          }
          // End
          let obj = {
            ...this.document,
            uid: auth.currentUser.uid,
            createdAt: FieldValue.serverTimestamp(),
            state: true
          }
          // Save in firestore
          const doc = await db.collection('posts').add({
            ...obj,
            createdAt: FieldValue.serverTimestamp()
          })
          obj.id = doc.id
          this.$emit('closeSidebar')
          this.$emit('add', obj)
          this.$vs.notify({
            color: 'success',
            title: 'Posts',
            text: 'Post creado correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Save image in firebase storage
     * @param image
     * @returns {Promise<unknown>}
     */
    submitImages (image) {
      return new Promise((resolve, reject) => {
        let imageName = Math.random().toString(36).substr(1, 20)
        let fileExtension = '.' + image.name.split('.').pop()
        let imageNameFinal = imageName + fileExtension

        let storageRef = storage.ref(`posts/${imageNameFinal}`)
        storageRef.put(image)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                resolve(url)
              })
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    /**
     * Load image
     * @param input
     */
    updateImage (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          if (input.srcElement.id === 'image1') {
            this.$set(this.document, 'image1', e.target.result)
            this.newImage1 = input.target.files[0]
            if (this.newImage1.size > 4000000) {
              this.document.image1 = null
              this.newImage1 = null

              this.$vs.notify({
                color: 'warning',
                title: '¡Oh no!',
                text: 'Imagen supera máximo de 4MB'
              })
            }
          }
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    /**
     * Reset values
     */
    initValues () {
      this.document = {}
      this.newImage1 = null
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 650px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}

.vdp-datepicker__clear-button {
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>
